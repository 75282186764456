<script lang="ts" setup>
	import type { LoginResponse } from "~/server/api/auth/login.post";

	definePageMeta({
		layout: "public",
	});

	const state = ref<"input" | "error" | "complete" | "disabled">("input");
	const email = ref<string>("");

	function validator(value: string): boolean {
		const trimmed = value.trim();

		if (!emailValidator(trimmed)) {
			return false;
		}

		if (!trimmed.endsWith("@astonmartin.com") && !trimmed.endsWith("@domorewithless.dev")) {
			return false;
		}

		return true;
	}

	const login = async function () {
		try {
			const { status } = await $fetch<LoginResponse>("/api/auth/login", {
				method: "POST",
				body: {
					email: email.value,
				},
			});

			if (status) {
				state.value = "complete";
			} else {
				state.value = "disabled";
			}
		} catch (error) {
			state.value = "error";
			console.error(error);
		}
	};

	const autosize = ref<HTMLElement | null>(null);
	const panel = ref<HTMLElement | null>(null);

	function updateHeight() {
		if (autosize.value && panel.value) {
			if (autosize.value.scrollHeight > 0) {
				panel.value.style.height = autosize.value.scrollHeight + 122 + "px";
			}
		}
	}

	function autoHeight() {
		if (autosize.value && panel.value) {
			panel.value.style.height = "auto";
		}
	}
</script>

<template>
	<Head>
		<Title>Sign in</Title>
	</Head>

	<div class="background"></div>

	<div ref="panel" class="flex-vertical login shadow-large panel border-beige">
		<div ref="autosize" class="autosize">
			<Transition
				name="slide-fade"
				mode="out-in"
				:on-appear="updateHeight"
				:on-after-appear="updateHeight"
				:on-after-leave="updateHeight"
				:on-after-enter="
					() => {
						updateHeight();
						autoHeight();
					}
				"
				:on-before-appear="updateHeight"
				:on-before-enter="updateHeight"
				:on-before-leave="updateHeight"
				:on-enter="updateHeight"
				:on-leave="updateHeight"
			>
				<div class="state flex-vertical input" v-if="state === 'input'">
					<logo />
					<div class="title flex-vertical">
						<h2>Sign in to your account</h2>
						<p class="medium">We’ll send you a link to login with.</p>
					</div>

					<InputForm :action="login" v-slot="{ form, validate, processing, submit, isValid }" id="input">
						<InputTextField
							:disabled="processing"
							:form="form"
							:validator="validator"
							placeholder="Work email"
							autocomplete="on"
							type="email"
							:required="true"
							v-model:value="email"
						></InputTextField>

						<Button size="large" :loading="processing" :state="isValid === true ? 'primary' : 'disabled'" @click="submit">Send email</Button>
					</InputForm>
				</div>

				<div class="state flex-vertical error" v-else-if="state === 'error'">
					<logo />
					<div class="title flex-vertical">
						<h2>Something went wrong</h2>
						<p class="medium">Please contact your administrator.</p>
					</div>
					<InputForm id="error" v-slot="{ form }">
						<InputTextField disabled :form="form" :validator="validator" placeholder="Work email" autocomplete="on" type="email" :required="true" v-model:value="email"></InputTextField>

						<Button size="large" state="secondary" @click="state = 'input'">Try again</Button>
					</InputForm>
				</div>

				<div class="state flex-vertical disabled" v-else-if="state === 'disabled'">
					<logo />
					<div class="title flex-vertical">
						<h2>
							Your account has
							<br />
							been disabled
						</h2>
						<p class="medium">Please contact your administrator.</p>
					</div>
					<InputForm id="disabled" v-slot="{ form }">
						<InputTextField disabled :form="form" :validator="validator" placeholder="Work email" autocomplete="on" type="email" :required="true" v-model:value="email"></InputTextField>

						<Button size="medium" state="secondary" @click="state = 'input'">Try again</Button>
					</InputForm>
				</div>

				<div class="state flex-vertical complete" v-else-if="state === 'complete'">
					<logo />
					<div class="title flex-vertical">
						<h2>Check your email</h2>
						<p class="medium">We’ve sent you a link to login with.</p>
					</div>

					<InputForm id="complete" v-slot="{ form }">
						<InputTextField disabled :form="form" :validator="validator" placeholder="Work email" autocomplete="on" type="email" :required="true" v-model:value="email"></InputTextField>

						<div class="flex-vertical gap-10 bar">
							<p class="small">No link? Check your spam folder.</p>

							<Button size="medium" state="secondary" @click="state = 'input'">Resend email</Button>
						</div>
					</InputForm>
				</div>
			</Transition>
		</div>
	</div>
</template>

<style scoped lang="scss">
	.login {
		justify-content: center;
		align-items: center;
		gap: 30px;
		z-index: 1;
		transition: all 0.1s linear;
		overflow: hidden;
		height: auto;
		max-height: fit-content;
		padding-top: 60px;
		padding-bottom: 60px;

		.slide-fade-enter-active {
			transition: all 0.4s $easeInOutExpo;
		}

		.slide-fade-leave-active {
			transition: all 0.4s $easeInOutExpo;
		}

		.slide-fade-enter-from {
			transform: translateX(20px);
			opacity: 0;
		}
		.slide-fade-leave-to {
			transform: translateX(-20px);
			opacity: 0;
		}

		.autosize {
			display: flex;
			overflow: hidden;
			transition: all 0.4s $easeInOutExpo;
		}

		.state {
			gap: 25px;
			width: 500px;
			padding-left: 60px;
			padding-right: 60px;

			box-sizing: border-box;

			.title {
				width: 100%;
				text-align: center;
				gap: 5px;
			}

			.input-form {
				overflow: visible;
				button {
					margin-top: 10px;
				}
			}
			.bar {
				align-items: center;
			}
		}

		.state.complete {
			.input-form {
				:deep(.input-text-field) {
					input {
						background: var(--green-light);
						box-shadow: none;
					}
				}
			}
		}

		.state.disabled,
		.state.error {
			.input-form {
				:deep(.input-text-field) {
					input {
						background: var(--red-light);
						box-shadow: none;
					}
				}
			}
		}

		.logo {
			width: auto;
			height: 40px;
		}
	}
</style>
